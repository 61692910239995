*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}

/* Globaly used variables */
:root {
  --lightBackground: #fff;
  --darkBackground: #191919;
  --themeColor: #223d58;
  --lightTheme: #9e9eff;
  --themeColorRGB: 34, 61, 88;
  --themeColorFilter: invert(20%) sepia(13%) saturate(2155%) hue-rotate(169deg)
    brightness(97%) contrast(92%);
  --accentColor1: yellow;
  --maxTextWidth: 65ch;

  --ff-base: Arial, sans-serif;

  --fs-200: 0.875rem;
  --fs-300: 1rem;
  --fs-400: 1.125rem;
  --fs-500: 1.375rem;
  --fs-600: 1.75rem;
  --fs-625: 2rem;
  --fs-650: 2.5rem;
  --fs-700: 2.85rem;

  --fw-regular: 400;
  --fw-semi-bold: 600;
  --fw-bold: 800;

  --button-border-radius: 2rem;

  --poppy-width: 320px;
  --poppy-height: 376px;
}

/* Global button behaviour */
a:hover,
button:hover {
  opacity: 0.8;
  cursor: pointer;
}

a {
  color: var(--themeColor);
}

button {
  padding: 0.5rem 2rem;
  border-radius: var(--button-border-radius);
  font-size: large;
  color: white;
}

/* Globaly helpful styles */

.center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.semi-bold {
  font-weight: var(--fw-semi-bold);
}

h1,
h2,
h3 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  /* Not universally supported, but looks better */
  text-wrap: balance;
}

header p {
  text-align: center;
  margin: auto 0;
  font-weight: var(--fw-regular);
  font-size: var(--fs-700);
}

h1 {
  margin-top: 1em;
  font-size: var(--fs-650);
  font-weight: var(--fw-bold);
}

h2 {
  margin-top: 0.5em;
  font-size: var(--fs-625);
  font-weight: var(--fw-bold);
  text-align: left;
}

p {
  font-size: var(--fs-400);
  text-align: left;
  margin-left: 1rem;
  margin-right: 1rem;
}

.hidden {
  display: none;
}

.show {
  display: block;
}

.margin-top {
  margin-top: 1em;
}

.flex-space {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

/* Body */

body {
  font-family: var(--ff-base);
  font-size: var(--fs-400);
  font-weight: var(--fw-regular);
  line-height: 1.6;
}

/* Topbar */

.topbar {
  z-index: 3;
  min-height: 40px;
  display: flex;
  column-gap: 1rem;
  flex-wrap: wrap;
  justify-content: space-evenly;
  position: sticky;
  border-radius: 0 0 1rem 1rem;
  top: 0;
}

.selection {
  font-weight: var(--fw-semi-bold);
  background-color: white;
  color: var(--themeColor);
}

.topbar a {
  color: inherit;
  font-size: var(--fs-600);
  min-width: 60px;
  text-decoration: none;
}

.banner {
  position: relative;
  z-index: 2;
  border-radius: 0;
  background-color: var(--themeColor);
  color: white;
}

.nav-poppy {
  display: block;
  margin-block: 1rem;
  width: calc(var(--poppy-width) * var(--scale-factor));
  height: calc(var(--poppy-height) * var(--scale-factor));
}

.large {
  --scale-factor: 0.8;
}

.medium {
  --scale-factor: 0.7;
}

.small {
  --scale-factor: 0.6;
}

/* Main */

main {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  width: min(var(--maxTextWidth), 100%);
  gap: 1rem;
  position: relative;
}

/* Footer */

footer {
  display: flex;
  justify-content: space-evenly;
  width: min(var(--maxTextWidth) * 2, 100%);
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
  padding: 2rem 0.5rem 1rem 0.5rem;
  text-align: center;
  gap: 1rem;
  color: var(--themeColor);
}

footer a {
  color: inherit;
  font-weight: var(--fw-semi-bold);
  display: flex;
  flex-direction: column;
  gap: 2px;
  text-decoration: none;
}

footer img {
  max-height: 1em;
  object-fit: contain;
}

/**/
/* Home Page */
/**/

.noscript {
  margin: 0.5rem;
  padding: 0.5rem;
  color: white;
  background-color: red;
  border: 2px solid black;
}

.noscript a {
  color: white;
  text-decoration: underline;
}

.download {
  cursor: pointer;
}

.add-basket {
  display: flex;
  flex-direction: column;
  width: -moz-fit-content;
  width: fit-content;
  justify-content: center;
  margin-block: 1rem;
}

.basket-selector {
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: var(--themeColor);
  color: white;
}

.home-img {
  width: 100%;
  padding: 1rem;
  border-radius: 2rem;
  max-height: 500px;
  object-fit: contain;
}

.transition-img {
  transition: opacity 0.5s ease-in-out;
}

/* Canvas */

.canvas canvas {
  width: 320px;
  height: 376px;
}

.canvas {
  margin: 2rem auto 1rem auto;
  position: relative;
}

/* Canvas Input */

.canvas label img {
  width: 80px;
  opacity: 0.75;
}

.user-input {
  margin-left: 1rem;
  margin-right: 1rem;
}

.user-input-img {
  position: absolute;
  top: 41.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  cursor: pointer;
  padding: 1.55rem 1.3rem;
  border-radius: 50%;
}

.their-story {
  margin-left: 1rem;
  margin-right: 1rem;
}

.consent {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.consent input {
  margin-block: auto;
}

.consent-checkbox {
  cursor: pointer;
}

.reCreate {
  display: flex;
  gap: 1rem;
  margin-left: auto;
  margin-right: auto;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
}

.reCreate:hover {
  .reCreate-icon {
    transform: rotate(90deg);
  }
}

.reCreate-icon {
  max-width: 40px;
  margin-left: auto;
  transform: rotate(0deg);
  transition: transform 500ms ease;
  vertical-align: middle;
}

.buy-now {
  color: white;
  padding: 1rem 5rem;
  background-color: var(--themeColor);
  border-radius: var(--button-border-radius);
  text-decoration: none;
  font-size: large;
  margin: 1rem;
  border: none;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.disabled {
  pointer-events: none;
  opacity: 40%;
}

summary {
  margin-bottom: 0.5rem;
  cursor: pointer;
}

/*  */
/* About Page */
/*  */

/* Info */
.info {
  overflow: auto;
}

.info-img {
  /* Allow text to wrap around the image */
  float: left;
  margin-right: 2rem;
  display: block;
  width: 100%;
  max-width: 400px;
  border-radius: 50%;
  object-fit: cover;
  shape-outside: circle(50%);
}

.info-text {
  margin: 0.5rem 1rem;
}

/*  */
/*  Download Page */
/*  */

.user-poppy {
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.user-poppy img {
  width: 320px;
  border-color: red white;
  border-radius: 1rem;
  padding: 0.5rem;
  border: 2px dashed var(--darkBackground);
  outline: 2px dashed var(--lightBackground);
}

.user-story {
  pointer-events: none;
}

.wide-canvas canvas {
  width: 660px;
  height: 396px;
  border: 2px dashed var(--darkBackground);
  outline: 2px dashed var(--lightBackground);
  border-radius: 1rem;
}

.download-blurb {
  font-size: var(--fs-500);
  font-weight: var(--fw-semi-bold);
}

/*  */
/*  404 page */
/*  */
.err-404 {
  height: var(--poppy-height);
  width: var(--poppy-width);
  background-size: contain;
  background-repeat: no-repeat;
  background-origin: content-box;
  background-position: center;
  position: relative;
  margin-top: 1rem;
}

.on-poppy {
  position: absolute;
  left: 6%;
  top: 69%;
  color: white;
  font-size: var(--fs-500);
}

.on-poppy a {
  color: inherit;
  text-decoration: underline;
  font-weight: var(--fw-regular);
}

.on-poppy-header {
  color: white;
  margin-top: 0.5rem;
}

/*  */
/* Blog Page */
/*  */
.blog-story img {
  width: 95%;
  margin-top: 1rem;
}

.blog-search {
  height: 2em;
  width: 300px;
}

.blog-selector {
  display: flex;
  column-gap: 2rem;
  row-gap: 1rem;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.blog-selector button {
  background-color: var(--themeColor);
}

/*  */
/* Media Queries */
/*  */

@media (max-width: 2000px) {
  .large {
    display: none;
  }
}

@media (max-width: 1300px) {
  .medium {
    display: none;
  }
}

@media (max-width: 700px) {
  .banner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  /* Do not wrap text on small screens */
  .info-img {
    margin: 0.5rem auto 1rem auto;
    padding-inline: 0.5rem;
    float: none;
  }

  .wide-canvas canvas {
    width: 320px;
    height: 188px;
  }
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: var(--darkBackground);
  }

  .selection {
    color: white;
    background-color: var(--darkBackground);
  }

  footer {
    color: white;
  }

  a {
    color: var(--lightTheme);
  }

  .invert {
    filter: invert(1);
  }
}
